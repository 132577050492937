<template>
  <div
    class="assign-task-summary-overlay"
    @click.stop="$emit('close-summary')"
  >
    <div
      class="summary"
      @click.stop
    >
      <div class="summary-header">
        <h6>
          {{ $t('error-assign-machine.assign-task-summary.heading') }}
        </h6>
        <img
          :src="CloseIcon"
          alt="Icon"
          class="close-icon"
          @click="$emit('close-summary')"
        >
      </div>
      <div class="summary-body">
        <ul class="summary-list">
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.person') }}
            </span>
            <div class="person">
              <span
                class="person-badge"
                :style="{backgroundColor: getUserBadgeColor}"
              >
                {{ getUserFirstName[0] }}{{ getUserLastName[0] }}
              </span>
              {{ getUserFirstName }} {{ getUserLastName }}
            </div>
          </li>
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.dateTime') }}
            </span>
            <div class="date-time bold">
              {{ dateHelper(summaryData.createdAt) }} {{ timeHelper(summaryData.createdAt) }}
            </div>
          </li>
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.category') }}
            </span>
            <div class="category">
              {{ summaryData.category }}
            </div>
          </li>
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.place') }}
            </span>
            <div class="place">
              {{ summaryData.hall }}
            </div>
          </li>
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.type') }}
            </span>
            <div
              v-if="summaryData.machineType === 'MACHINE'"
              class="type"
            >
              {{ $t('error-assign-machine.assign-task-summary.machine') }}
            </div>
            <div
              v-if="summaryData.machineType === 'BUILDING_EQUIPMENT'"
              class="type"
            >
              {{ $t('error-assign-machine.assign-task-summary.building') }}
            </div>
            <div
              v-if="summaryData.machineType === 'OTHER_EQUIPMENT'"
              class="type"
            >
              {{ $t('error-assign-machine.assign-task-summary.devices') }}
            </div>
            <div
              v-if="summaryData.machineType === 'WORKSHOP'"
              class="type"
            >
              {{ $t('error-assign-machine.assign-task-summary.workshop') }}
            </div>
          </li>
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.actualMachine') }}
            </span>
            <div class="actual-machine">
              {{ summaryData.machine }}
            </div>
          </li>
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.errorType') }}
            </span>
            <div class="error-type">
              {{ summaryData.name }}
            </div>
          </li>
          <li class="summary-item">
            <span class="label">
              {{ $t('error-assign-machine.assign-task-summary.status') }}
            </span>
            <div
              v-if="summaryData.status === 'STOPPED'"
              class="status stopped"
            >
              <img
                :src="CardIcon1"
                :alt="$t('error-assign-machine.assign-task-summary.stopped')"
                class="status-icon"
              >
              {{ $t('error-assign-machine.assign-task-summary.stopped') }}
            </div>
            <div
              v-if="summaryData.status === 'LIMITED'"
              class="status limited"
            >
              <img
                :src="CardIcon2"
                :alt="$t('error-assign-machine.assign-task-summary.limited')"
                class="status-icon"
              >
              {{ $t('error-assign-machine.assign-task-summary.limited') }}
            </div>
            <div
              v-if="summaryData.status === 'POOR_QUALITY'"
              class="status poor-quality"
            >
              <img
                :src="CardIcon3"
                :alt="$t('error-assign-machine.assign-task-summary.poorQuality')"
                class="status-icon"
              >
              {{ $t('error-assign-machine.assign-task-summary.poorQuality') }}
            </div>
            <div
              v-if="summaryData.status === 'PLANNED_MAINTENANCE'"
              class="status planned-maintenance"
            >
              <img
                :src="CardIcon4"
                :alt="$t('error-assign-machine.assign-task-summary.plannedMaintenance')"
                class="status-icon"
              >
              {{ $t('error-assign-machine.assign-task-summary.plannedMaintenance') }}
            </div>
            <div
              v-if="summaryData.status === 'RUNNING'"
              class="status running"
            >
              <img
                :src="CardIcon5"
                :alt="$t('error-assign-machine.assign-task-summary.running')"
                class="status-icon"
              >
              {{ $t('error-assign-machine.assign-task-summary.running') }}
            </div>
          </li>
        </ul>
        <div class="description-container">
          <textarea
            id="description"
            v-model="assignDesc"
            name="description"
            autofocus
            class="description-area"
            :placeholder="$t('error-assign-machine.assign-task-summary.placeholder')"
          />
        </div>
      </div>
      <div class="summary-footer">
        <custom-button
          class="button-marginer"
          :fullwidth="windowWidth <= 960 ? true : false"
          @click="assignUserToTask()"
        >
          {{ $t('error-assign-machine.assign-task-summary.assignTask') }}
        </custom-button>
        <custom-button
          v-if="windowWidth > 960"
          type="normal-custom-button"
          @click="$emit('close-summary')"
        >
          {{ $t('error-assign-machine.assign-task-summary.cancel') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseIcon from '@/assets/icons/close_dark.svg'
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'
import { timeHelper, dateHelper } from '@/helpers/dateHelpers'

export default {
  name: 'AssignTaskSummary',
  components: {
    CustomButton
  },
  props: {
    summaryData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      CloseIcon,
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5,
      assignDesc: '',
      windowWidth: 0
    }
  },
  computed: {
    ...mapGetters('ErrorAssign', ['getUserFirstName', 'getUserLastName', 'getUserId', 'getUserBadgeColor'])
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    this.getOldDescription()
  },
  methods: {
    dateHelper,
    timeHelper,
    getOldDescription () {
      this.assignDesc = this.summaryData.description
    },
    async assignUserToTask () {
      const sendData = {
        userId: this.$route.params.userId,
        machineErrorId: this.summaryData.id
      }
      const sendDescription = {
        description: this.assignDesc
      }
      await this.$axios.post('/machine-errors/attach-user-to-error', sendData)
      await this.$axios.post(`/tasks/${this.summaryData.id}`, sendDescription)
      this.$store.commit('ErrorAssign/RESET')
      this.$router.push({ name: 'ErrorAssignMachineUsers' })
    },
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.assign-task-summary-overlay {
  z-index: 99998;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.75);

  div.summary {
    z-index: 99999;
    width: 100%;
    max-width: 541px;
    height: 100%;
    background-color: $primary-white;
    position: relative;

    div.summary-header {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 24px;
      border-bottom: 1px solid $primary-lightgrey;
      background-color: $primary-white;

      h6 {
        font-weight: 500;
      }

      .close-icon {
        cursor: pointer;
      }
    }

    div.summary-body {
      padding-top: 73px;

      ul.summary-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0 24px;
        background-color: #F8F8F8;

        li.summary-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $primary-lightgrey;
          padding: 22px 0;

          &:last-child {
            border-bottom: none;
          }

          span.label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: $primary-grey;
          }

          div.person,
          div.date-time,
          div.category,
          div.place,
          div.type,
          div.actual-machine,
          div.error-type,
          div.status {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;

            span.person-badge {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              color: $primary-white;
              margin-right: 12px;
              font-weight: 500;
            }

            &.bold {
              font-weight: 500;
            }

            &.stopped {
              color: $primary-red;
            }

            &.limited,
            &.poor-quality {
              color: $status-yellow;
            }

            &.planned-maintenance,
            &.running {
              color: $status-green;
            }

            img.status-icon {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
          }
        }
      }

      div.description-container {
        width: 100%;
        padding: 28px 24px;

        textarea.description-area {
          width: 100%;
          height: 120px;
          padding: 16px;
          color: rgba(0, 0, 0, 0.87);
          border: 1px solid rgba(0, 0, 0, 0.42);
          outline: none;
          resize: none;
        }
      }
    }

    div.summary-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 92px;
      border-top: 1px solid $primary-lightgrey;
      background-color: $primary-white;
      display: flex;
      align-items: center;
      padding: 20px 24px;

      .button-marginer {
        margin-right: 16px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.assign-task-summary-overlay {
    div.summary {
      width: 95%;

      div.summary-body {
        height: 100%;
        padding-bottom: 92px;
        overflow: scroll;
      }
    }
  }
}
</style>
