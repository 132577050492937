<template>
  <div class="select-task-table-component">
    <div class="table-first-row">
      <custom-input
        v-model="search"
        no-label
        border
        :placeholder="$t('error-assign-machine.select-task-table.searchPlaceholder')"
        class="search-input"
      >
        <template v-slot:leftIcon>
          <img
            :src="SearchIcon"
            alt="Icon"
          >
        </template>
      </custom-input>
    </div>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :search="search"
      :hide-default-footer="windowWidth <= 960 ? true : false"
      :disable-pagination="windowWidth <= 960 ? true : false"
      mobile-breakpoint="960"
      @click:row="rowClick"
    >
      <!-- eslint-disable -->
      <template v-slot:header.createdAt="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.category="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.machine="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.status="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.errorStatus="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <div class="created-at">
          {{ dateHelper(item.createdAt) }} {{ timeHelper(item.createdAt) }}
        </div>
      </template>
      <template v-slot:item.machine="{ item }">
        <div class="machine">
          {{ item.machine }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div
          v-if="item.status === 'STOPPED'"
          class="status"
        >
          <img
            :src="CardIcon1"
            :alt="$t('error-report.machines-report.stopped')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === 'LIMITED'"
          class="status"
        >
          <img
            :src="CardIcon2"
            :alt="$t('error-report.machines-report.limited')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === 'POOR_QUALITY'"
          class="status"
        >
          <img
            :src="CardIcon3"
            :alt="$t('error-report.machines-report.poorQuality')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === 'PLANNED_MAINTENANCE'"
          class="status"
        >
          <img
            :src="CardIcon4"
            :alt="$t('error-report.machines-report.plannedMaintenance')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === 'RUNNING'"
          class="status"
        >
          <img
            :src="CardIcon5"
            :alt="$t('error-report.machines-report.running')"
            class="status-icon"
          >
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="name">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.errorStatus="{ item }">
        <div class="error-status">
          <div
            v-if="item.errorStatus === $t('dashboard.error-table.table.unassigned')"
            class="nobody"
          >
            {{ item.errorStatus }}
          </div>
          <div
            v-else
            class="assigned"
          >
            <img
              :src="AssignedIcon"
              alt="Icon"
              class="icon"
            >
            {{ item.errorStatus }}
          </div>
        </div>
      </template>
      <template v-slot:item.more="{ item }">
        <div
          class="more"
          @click="showSummary = true"
        >
          <img
            :src="ArrowRightIcon"
            alt="ArrowRightIcon"
            class="more-icon"
          >
        </div>
      </template>
      <!-- eslint-enable -->
    </v-data-table>
    <assign-task-summary
      v-if="showSummary"
      :summary-data="summaryData"
      @close-summary="handleCloseSummary()"
    />
  </div>
</template>

<script>
import CustomInput from '@/components/GlobalComponents/CustomInput.vue'
import SearchIcon from '@/assets/icons/search.svg'
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'
import AssignedIcon from '@/assets/icons/assigned_icon.svg'
import ArrowRightIcon from '@/assets/icons/chevron_right.svg'
import AssignTaskSummary from '@/components/GlobalComponents/Summarizations/AssignTaskSummary.vue'
import { timeHelper, dateHelper } from '@/helpers/dateHelpers'

export default {
  components: {
    CustomInput,
    AssignTaskSummary
  },
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      SearchIcon,
      search: '',
      headers: [
        { text: this.$t('dashboard.error-table.table.headers.dateTime'), value: 'createdAt' },
        { text: this.$t('dashboard.error-table.table.headers.machine'), value: 'machine' },
        { text: this.$t('dashboard.error-table.table.headers.status'), value: 'status' },
        { text: this.$t('dashboard.error-table.table.headers.type'), value: 'name' },
        { text: this.$t('dashboard.error-table.table.headers.jobStatus'), value: 'errorStatus' },
        { text: '', value: 'more', sortable: false }
      ],
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5,
      AssignedIcon,
      ArrowRightIcon,
      summaryData: {},
      showSummary: false,
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    dateHelper,
    timeHelper,
    rowClick (row) {
      this.summaryData = row
    },
    handleCloseSummary () {
      this.showSummary = false
      this.summaryData = {}
    },
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.select-task-table-component {
  width: 100%;
  max-width: 1108px;
  background-color: $primary-white;
  padding: 32px;

  div.table-first-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .search-input {
      max-width: 238px;
      font-size: 14px;
    }
  }

  // Table header
  .header-text {
    font-weight: 500;
    color: $primary-grey;
    letter-spacing: 0.4px;
  }

  // Table body
  div.created-at,
  div.category,
  div.machine,
  div.status,
  div.name,
  div.error-status {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 56px;
    color: $primary-black;

    img.status-icon {
      width: 24px;
      height: 24px;
    }

    div.nobody {
      display: flex;
      align-items: center;
      background-color: #F5F5F5;
      height: 24px;
      padding: 2px 24px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $primary-black;
    }

    div.assigned {
      display: flex;
      align-items: center;
      background-color: $status-green;
      // height: 24px;
      padding: 2px 12px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $primary-white;

      img.icon {
        margin-right: 6px;
        width: 12px;
        height: 12px;
      }
    }
  }

  div.more {
    cursor: pointer;

    img.more-icon {
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (max-width: $mobile) {
  div.select-task-table-component {
    padding: 31px 24px;

    div.table-first-row {
      justify-content: flex-start;

      .search-input {
        max-width: 100%;
      }
    }
  }
}
</style>
