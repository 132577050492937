<template>
  <div class="error-assign-select-task-wrapper">
    <div class="error-assign-select-task-container">
      <div class="top-part">
        <img
          :src="ButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.go(-1)"
        >
        <h4>{{ $t('error-assign-machine.select-user.select-task') }}</h4>
      </div>
      <select-task-table />
    </div>
  </div>
</template>

<script>
import ButtonIcon from '@/assets/arrow_button.svg'
import SelectTaskTable from './SelectTaskTable'

export default {
  name: 'SelectTask',
  components: {
    SelectTaskTable
  },
  data () {
    return {
      ButtonIcon
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-assign-select-task-wrapper {
  width: 100%;
  padding-top: 128px; // header + breadcrumbs

  div.error-assign-select-task-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.top-part {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 1108px;
      margin: 53px 0 64px 0;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-assign-select-task-wrapper {
    position: relative;
    padding: 64px 0 100px 0;

    div.error-assign-select-task-container {
      padding: 0;

      div.top-part {
        margin: 0;
        padding: 24px;

        img.back-button {
          z-index: 1;
          position: fixed;
          bottom: 100px;
          right: 16px;
          margin: 0;
        }

        h4 {
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}
</style>
