<template>
  <div
    class="select-form"
    :class="[{ 'form-group': isForm }]"
  >
    <label
      v-show="!noLabel"
      :style="labelStyles"
      :class="[customInputType, { styled: !!labelStyles }]"
      :for="defaultId"
    >
      <!-- @slot Default slot for label -->
      <slot />
    </label>
    <section
      :class="{
        'no-left-border': leftIconNoBorder,
        'no-right-border': rightIconNoBorder,
        focused: isFocused,
        'is-error': error,
      }"
      :border="border"
    >
      <span
        v-if="!!$slots.leftIcon"
        class="left-icon"
        :class="{ filled: filled, 'is-error': error }"
      >
        <!-- @slot Use this slot to place the left icon content -->
        <slot name="leftIcon" />
      </span>
      <input
        :id="defaultId"
        ref="input"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :class="[
          customInputType,
          {
            'rounded-right': $slots.leftIcon,
            'rounded-left': $slots.rightIcon,
            'is-error': error
          },
        ]"
        :disabled="disabled"
        :min="Number(min)"
        :max="Number(max)"
        :maxlength="Number(maxlength)"
        :name="defaultName"
        :pattern="pattern"
        :placeholder="placeholder"
        :required="required"
        :readonly="readonly"
        :step="Number(step)"
        :title="title"
        :type="customInputType"
        :value="value"
        @input="updateCode($event.target.value)"
        @blur="onBlur($event)"
        @focus="onFocus($event)"
        @keydown="onKeyDown($event)"
        @keyup="onKeyUp($event)"
        @keypress="onKeyPress($event)"
      >
      <span
        v-if="!!$slots.rightIcon"
        class="right-icon"
        :class="{ filled: filled, 'is-error': error }"
      >
        <!-- @slot Use this slot to place the Right icon content -->
        <slot name="rightIcon" />
      </span>
    </section>
    <div
      v-if="error"
      class="error-message"
    >
      <!-- @slot Use this slot to show error message -->
      <slot name="errorMessage" />
    </div>
  </div>
</template>

<script>
import createRandomId from '@/helpers/createRandomId'

export default {
  name: 'CustomInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    /**
     * If true is passed inside a form tag it ads form-group class
     */
    isForm: {
      type: Boolean,
      default: true,
      required: false
    },
    /**
     * If true is passed inside a form tag it will not show label.
     * Use title in that case
     */
    noLabel: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * You can pass a descriptive title which
     * will work instead of label if needed.
     * On a long hover it will create a popup
     */
    title: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Placeholder text
     */
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    /**
     * The autofocus attribute inside inputs
     */
    autofocus: {
      type: Boolean,
      default: false
    },
    /**
     * The autocomplete attribute inside inputs
     * Values can be ['on', 'off']
     */
    autocomplete: {
      type: String,
      default: 'off',
      validator (value) {
        return ['on', 'off'].includes(value)
      }
    },
    /**
     * Name which needs to be added to keep with standards.
     * If none is given the ID will be the name
     */
    name: {
      type: String,
      default: ''
    },
    /**
     * The id of the input.
     * If not given a random id will be generated
     */
    id: {
      default: '',
      required: false,
      type: String
    },
    /**
     * Value of the input
     */
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    /**
     * Disabled state of the checkbox
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Decide if the input is has to be filled in by user.
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Style object for label styling
     */
    labelStyles: {
      type: Object,
      default () {}
    },
    /**
     * Adding filling on icons
     */
    filled: {
      type: Boolean,
      default: false
    },
    /**
     * Max value for number type input
     */
    max: {
      type: [Number, String],
      default: 10000000000
    },
    /**
     * Maxlength for string input
     * Used default is the official HTML default for  maxlength
     */
    maxlength: {
      type: [Number, String],
      default: 524288
    },
    /**
     * Min value for Number type input
     */
    min: {
      type: [Number, String],
      default: 0
    },
    /**
     * Step for number type input
     */
    step: {
      type: [Number, String],
      default: 1
    },
    /**
     * The type of input.
     * Valid values are ['text', 'color', 'email',
     * 'hidden', 'number', 'password', 'submit']
     */
    customInputType: {
      type: String,
      default: 'text',
      required: false,
      validator (prop) {
        return [
          'text',
          'color',
          'email',
          'hidden',
          'number',
          'password',
          'submit'
        ].includes(prop) > -1
      }
    },
    /**
     * If left icon should not have right border
     */
    leftIconNoBorder: {
      type: Boolean,
      default: false
    },
    /**
     * If right icon should not have left border
     */
    rightIconNoBorder: {
      type: Boolean,
      default: false
    },
    /**
     * If component should display is-error class
     */
    error: {
      type: Boolean,
      default: false
    },
    /**
     * Work In Progress
     * HTML pattern for validation
     * we still need to improve component validation
     */
    pattern: {
      type: String,
      default: null
    },
    /**
     * If input is readonly
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * If input have border
     */
    border: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      // We check for given id and if there is none we create one
      defaultId: this.id || createRandomId('TextInput'),
      defaultName: this.name || createRandomId('name'),
      isFocused: false
    }
  },
  methods: {
    updateCode (value) {
      // You can do validations here or whatever
      if (this.customInputType === 'text') {
        // DO SOMETHING
        // console.log('I am a text type')
      } else if (this.customInputType === 'color') {
        // DO SOMETHING
        // console.log('I am a color type')
      }
      /**
       * Emitting value
       * Allows us to add middleware
       *
       * @event input
       * @property {value}
       * @customInputType {any}
       */

      if (this.customInputType === 'number') {
        this.$emit('input', value === '' ? value : Number(value))
      } else {
        this.$emit('input', value)
      }
    },
    onBlur (value) {
      /**
       * Emitting value on blur
       *
       * @event blur
       * @property {value}
       * @customInputType {any}
       */
      this.isFocused = false
      if (this.customInputType === 'number') {
        if (this.value === '') {
          this.$emit('input', this.value)
        } else if (this.value > Number(this.max)) {
          this.$emit('input', Number(this.max))
        } else if (this.value < Number(this.min)) {
          this.$emit('input', Number(this.min))
        }
      }
      this.$emit('blur', value)
    },
    onFocus (value) {
      /**
       * Emitting value on focus
       *
       * @event focus
       * @property {value}
       * @customInputType {any}
       */
      this.isFocused = true
      this.$emit('focus', value)
    },
    onKeyPress ($event) {
      /**
       * Triggered on button Keypress state.
       *
       * @event focut
       * @property {event}
       * @customInputType {object}
       */
      this.$emit('keypress', $event)
    },
    onKeyDown ($event) {
      /**
       * Triggered on button KeyDown state.
       *
       * @event focut
       * @property {event}
       * @customInputType {object}
       */
      this.$emit('keydown', $event)
    },
    onKeyUp ($event) {
      /**
       * Triggered on button keyUp state.
       *
       * @event focut
       * @property {event}
       * @customInputType {object}
       */
      this.$emit('keyup', $event)
    }
  }
}
</script>

<style lang="scss">
div.select-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label {
    color: $primary-white;
    line-height: 24px;
    margin-bottom: 4px;
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 52px;
    padding: 8px 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $primary-black;
    background-color: $primary-white;

    &[border] {
      border: 1px solid $primary-lightgrey;
    }

    &.is-error {
      border: 1px solid $status-red;
      color: $status-red;
    }

    input[type="submit"] {
      cursor: pointer;

      &.transparent {
        background-color: transparent;

        // &:hover {
        //   color: $grey-1;
        //   border: 1px solid $grey-10;
        // }

        // &:focus {
        //   color: $grey-1;
        //   border: 1px solid $grey-10;
        // }

        // &:active {
        //   color: $grey-1;
        //   border: 1px solid $grey-10;
        // }

        // &[disabled] {
        //   pointer-events: none;
        //   cursor: not-allowed;
        //   color: $grey-1;
        //   border: 1px solid lightgray;
        // }
      }

      // &:hover {
      //   background-color: lighten($color: $grey-10, $amount: 20%);
      // }

      // &:focus {
      //   background-color: lighten($color: $grey-10, $amount: 30%);
      // }

      // &:active {
      //   background-color: lighten($color: $grey-10, $amount: 40%);
      // }

      // &[disabled] {
      //   color: lightgrey;
      //   background-color: lighten($color: $grey-10, $amount: 60%);
      //   border: 1px solid lighten($color: $grey-10, $amount: 60%);
      // }
    }

    input {
      width: 100%;
      height: 100%;

      &::placeholder {
        color: $primary-grey;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &.rounded-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.rounded-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.is-error {
        &::placeholder {
          color: $status-red;
        }
      }
    }

    &.no-left-border > input {
      border-left: 0;
    }

    &.no-right-border > input {
      border-right: 0;
    }

    .left-icon {
      display: flex;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      // background-color: $basic-input-background-color;
      // border-top: $border-width solid $basic-input-icon-border-color;
      // border-bottom: $border-width solid $basic-input-icon-border-color;
      // border-left: $border-width solid $basic-input-icon-border-color;
      // border-top-left-radius: $border-radius;
      // border-bottom-left-radius: $border-radius;

      // &.filled {
      //   background-color: $basic-input-icon-background-color;
      // }

      // &.is-error {
      //   border-color: $basic-input-error-border-color;
      // }

      svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: $primary-grey;
      }
    }

    .right-icon {
      display: flex;
      justify-content: center;
      width: 24px;
      height: 24px;
      // background-color: $basic-input-background-color;
      // border-top: $border-width solid $basic-input-icon-border-color;
      // border-right: $border-width solid $basic-input-icon-border-color;
      // border-bottom: $border-width solid $basic-input-icon-border-color;
      // border-top-right-radius: $border-radius;
      // border-bottom-right-radius: $border-radius;

      // &.filled {
      //   background-color: $basic-input-icon-background-color;
      // }

      // &.is-error {
      //   border-color: $basic-input-error-border-color;
      // }

      svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: $primary-grey;
      }
    }
  }

  section:focus-within,
  section.focused {
    &.no-left-border {
      input {
        border-left: 0;
      }

      // .left-icon,
      // .right-icon {
      //   border-color: $basic-input-focus-border-color;

      //   &.is-error {
      //     border-color: $basic-input-error-border-color;
      //   }
      // }
    }

    &.no-right-border {
      input {
        border-right: 0;
      }

      // .left-icon,
      // .right-icon {
      //   border-color: $basic-input-focus-border-color;

      //   &.is-error {
      //     border-color: $basic-input-error-border-color;
      //   }
      // }
    }
  }

  div.error-message {
    color: $status-red;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-top: 8px;
  }
}
</style>
