var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-form",class:[{ 'form-group': _vm.isForm }]},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noLabel),expression:"!noLabel"}],class:[_vm.customInputType, { styled: !!_vm.labelStyles }],style:(_vm.labelStyles),attrs:{"for":_vm.defaultId}},[_vm._t("default")],2),_c('section',{class:{
      'no-left-border': _vm.leftIconNoBorder,
      'no-right-border': _vm.rightIconNoBorder,
      focused: _vm.isFocused,
      'is-error': _vm.error,
    },attrs:{"border":_vm.border}},[(!!_vm.$slots.leftIcon)?_c('span',{staticClass:"left-icon",class:{ filled: _vm.filled, 'is-error': _vm.error }},[_vm._t("leftIcon")],2):_vm._e(),_c('input',{ref:"input",class:[
        _vm.customInputType,
        {
          'rounded-right': _vm.$slots.leftIcon,
          'rounded-left': _vm.$slots.rightIcon,
          'is-error': _vm.error
        },
      ],attrs:{"id":_vm.defaultId,"autocomplete":_vm.autocomplete,"autofocus":_vm.autofocus,"disabled":_vm.disabled,"min":Number(_vm.min),"max":Number(_vm.max),"maxlength":Number(_vm.maxlength),"name":_vm.defaultName,"pattern":_vm.pattern,"placeholder":_vm.placeholder,"required":_vm.required,"readonly":_vm.readonly,"step":Number(_vm.step),"title":_vm.title,"type":_vm.customInputType},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateCode($event.target.value)},"blur":function($event){return _vm.onBlur($event)},"focus":function($event){return _vm.onFocus($event)},"keydown":function($event){return _vm.onKeyDown($event)},"keyup":function($event){return _vm.onKeyUp($event)},"keypress":function($event){return _vm.onKeyPress($event)}}}),(!!_vm.$slots.rightIcon)?_c('span',{staticClass:"right-icon",class:{ filled: _vm.filled, 'is-error': _vm.error }},[_vm._t("rightIcon")],2):_vm._e()]),(_vm.error)?_c('div',{staticClass:"error-message"},[_vm._t("errorMessage")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }